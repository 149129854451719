export const menuItemsClient = [
    {
        id: 1,
        label: 'Dashboard',
        link: '/dashboard',
        icon: 'bx bx-home-circle',
    },
    {
        id: 2,
        label: 'Add Story',
        link: '/add-new-story',
        icon: 'mdi mdi-file-plus-outline',
    },
    // {
    //     id: 2,
    //     label: 'Story',
    //     icon: 'mdi mdi-file-document-outline',
    //     subItems: [
    //         {
    //             id: 2,
    //             label: "Stories",
    //             link: "/stories-listing",
    //             icon: 'mdi mdi-file-document-outline',
    //             parentId: 2
    //         },
    //     ]
        
    // },
    {
        id: 3,
        label: "Add-on Service",
        link: "/add-on-service",
        icon: 'mdi mdi-file-document-outline',
    },
    // {
    //     id: 3,
    //     label: 'Payment History',
    //     link: '/payment-history',
    //     icon: 'mdi mdi-account-cash-outline ',
    // },
    // {
    //     id: 3,
    //     label: 'Orders',
    //     link: '/orders',
    //     icon: 'bx bx-copy-alt',
    // },
    
    // {
    //     id: 2,
    //     label: 'Client',
    //     icon: 'bx bx-user-plus',
    //     subItems: [
    //         {
    //             id: 1,
    //             label: "Add Client",
    //             link: "/add-client",
    //             icon: 'bx bx-user-plus',
    //             parentId: 2
    //         },
    //         {
    //             id: 2,
    //             label: 'Client',
    //             link: '/client',
    //             icon: 'bx bx-user',
    //             parentId: 2
    //         },
    //     ]
    // },
    // {
    //     id: 2,
    //     label: 'Story',
    //     icon: 'mdi mdi-file-document-outline',
    //     subItems: [
    //         {
    //             id: 1,
    //             label: 'Add Story',
    //             link: '/add-story',
    //             icon: 'mdi mdi-file-plus-outline',
    //             parentId: 2
    //         },
    //         {
    //             id: 2,
    //             label: "Stories",
    //             link: "/stories",
    //             icon: 'mdi mdi-file-document-outline',
    //             parentId: 2
    //         },
    //     ]
        
    // },
    // {
    //     id: 4,
    //     label: 'Journals',
    //     icon: 'mdi mdi-book-outline',
    //     subItems: [
    //         {
    //             id: 1,
    //             label: 'Add Journal',
    //             link: '/add-journal',
    //             icon: 'mdi mdi-book-edit-outline',
    //             parentId: 4
    //         },
    //         {
    //             id: 2,
    //             label: "Journals",
    //             link: "/journals",
    //             icon: 'mdi mdi-book-outline',
    //             parentId: 4
    //         },
    //     ]
    // },
    // {
    //     id: 5,
    //     label: 'All Task',
    //     link: '/all-task',
    //     icon: 'mdi mdi-file-document-outline'
    // },
    // {
    //     id: 6,
    //     label: 'All Responses',
    //     link: '/all-response',
    //     icon: 'mdi mdi-message-text-clock'
    // }
];

